<!--
 * @Author: lzh
 * @Date: 2022-07-08 16:51:57
 * @LastEditors: lbh
 * @LastEditTime: 2023-02-04 11:50:14
 * @Description: file content
-->
<template>
  <div class="edit-partner">
    <h3>選擇圖片</h3>
    <div class="item-box">
      <template v-for="(item, index) in configs.items">
        <div
          class="cell-b item"
          :key="index"
        >
          <el-button-group class="btn-group">
            <el-button
              type="primary"
              icon="el-icon-upload2"
              :disabled="index == 0"
              size="mini"
              @click="itemCheck(index,index-1)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-download"
              :disabled="index == configs.items.length-1"
              size="mini"
              @click="itemCheck(index,index+1)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              :disabled="configs.items.length == 1"
              @click="itemDel(index)"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-plus"
              size="mini"
              @click="itemAdd(index)"
            ></el-button>
          </el-button-group>
          <selfUpload
            v-model="item.img"
            type="default"
            @change="setValue('items')"
          />
          <el-input
            v-model="item.alt"
            type="textarea"
            @change="setValue('items')"
            placeholder="請輸入圖片描述"
          > </el-input>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'edit-partner',
  props: {
    configs: {
      default () {
        return {
          items: [],
        };
      },
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  },
};
</script>

<style lang="less" scoped>
.edit-partner {
  .item-box {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 100%;
      .btn-group {
        margin-bottom: 10px;
      }
      /deep/ .com__upload-box {
        height: auto;
        width: 100%;
      }
      /deep/.avatar-uploader {
        height: auto;
        width: 100%;
      }
      /deep/.el-upload {
        height: auto;
        width: 100%;
      }
      /deep/.el-upload img {
        height: auto;
        width: 100%;
      }
    }
  }
}
</style>
